@charset "utf-8";

/*=================================
  common
=================================*/
body {
  @extend %font;
  position: relative;
  word-break: break-word;
}
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.note {
  font-size: 1.5rem;
  display: block;
}
.is-sp-only {
  display: none!important;
}
.is-text-center {
  text-align: center;
}
.is-text-right {
  text-align: right;
}
.is-text-note {
  font-size: 1.2rem;
  padding-left: 1em;
  text-indent: -1em;
  display: block;
}
.inner-wrap {
  max-width: $base-width;
  margin: 0 auto;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}

@media screen and (max-width: $display-width-s){
  /*=================================
    common
  =================================*/
  html,body {
    min-width: 320px;
  }
  .is-sp-only {
    display: inline-block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .note {
    font-size: 1.1rem;
  }
}